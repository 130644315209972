import React from "react"
import { Col, Container, Row } from "reactstrap"

const ReservationComponent = () => {
  return <section className="section section_reservation section_gray" id="section_reservation" style={{paddingBottom: 0}}>
    <Container>
      <Row>
        <Col>
          <h2 className="section__heading text-center">
            Make online reservation
          </h2>

          <p className="section__subheading text-center">
            We take reservations 7 days a week. For dinner on Friday and Saturday we have limited spaces and times. We do not take large bookings on Friday and Saturday nights.
          </p>

        </Col>
      </Row>
      <div className="row justify-content-lg-center  section_reservation__row">
        <div className="col-lg-8">
          <iframe title="reservation" id="eveve" src="https://nz4.eveve.com/tms/datecovers.php?est=ElHumero" frameBorder="0" scrolling="auto" width="100%" height="520" > Please upgrade your browser </iframe>
        </div>
      </div>
    </Container>
  </section>
}

export default ReservationComponent
