import React, { useState } from "react"
import * as R from "ramda"
import menuItems from "./menuItems"
import { Button } from "reactstrap"
import styled from "styled-components"

const StyledTitle = styled.h2`
color: #003893;
`

const MenuComponent = () => {
  const [selectedKey, setSelectedKey] = useState(R.keys(menuItems)[0])

  return <section className="section section_menu section_border_bottom">
    <div className="container">
      <div className="row">
        <div className="col">
          <StyledTitle className="section__heading text-center">
            Our menu
          </StyledTitle>
          <p className="section__subheading text-center">
            We have created a menu with traditional items of the Colombian Barbecue.
          </p>

        </div>
      </div>
      <div className="row">
        <div className="col">
          <nav className="section_menu__nav">
            <ul>
              {R.map((name) => {
                  return <li key={name} className={selectedKey === name ? "active" : ""}>
                    <Button tag="a" color="link" onClick={() => setSelectedKey(name)}>{name}</Button>
                  </li>
                }
                , R.keys(menuItems))}
            </ul>
          </nav>
        </div>
      </div>
      <div className="row section_menu__grid" id="menu_no-images">
        {R.map((item) => {
          return <div key={item.name} className="col-sm-6 section_menu__grid__item mains">
            <div className="section_menu__item">
              <div className="row">
                <div className="col-10">
                  <h4 className="text-danger">{item.name}</h4>
                  <p>
                    {item.description}
                  </p>
                </div>
                <div className="col-2">
                  <div className="section_menu__item__price text-center">
                     {item.value? `$${item.value}` : ''}
                  </div>
                </div>
              </div>
            </div>
          </div>
        }, R.values(menuItems[selectedKey]))}
      </div>
    </div>
  </section>
}

export default MenuComponent
